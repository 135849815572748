<template>
 <div class="wrapper">

      <div class="card">
          <img src="imgs/timCrd.png" alt="">
      </div>
      <div class="headline">
          <h1>
              <img src="imgs/headline.png" alt="Your chance to TAKE 10 is here!">
          </h1>
          <p v-if="state == 'error'" style="color: rgba(255, 0, 0, 1);">
              <strong>
                An error has occured. <br>
                Please contact to your supervisor.
              </strong>
          </p>
          <div class="actionBlock" v-if="state != 'error'" style="position: absolute; left: 50%; transform: translateX(-50%); text-align: center; height: 126px">
            <transition name="fade" mode="out-in">
              <p v-if="state == 'active'">
                  Click the button below to activate your $10 Tim Hortons eGift Card.
              </p>
            </transition>
            <transition name="fade" mode="out-in">
              <p v-show="showbtn">
                <button @click="claimGift" class="btn">Claim your eGift Card now</button>
              </p>
            </transition>
          </div>
          <div class="actionBlock" v-if="state != 'error'" style="position: absolute; left: 50%; transform: translateX(-50%); text-align: center; height: 126px">
            <transition name="fade" mode="in-out">
              <div v-show="state == 'loading'" class="lds-ring">
                <div></div><div></div><div></div><div></div>
              </div>
            </transition>
          </div>
      </div>

    </div>

    <div class="faq" >
      <p v-if="state == 'active'">
        Having trouble? <a href="https://company.timhortons.ca/ca/en/timcard/timcard-faq.php" target="_blank">Visit Tim Hortons’ eGift Card FAQs.</a>
      </p>
    </div>

</template>

<script>

import EventService from '@/services/EventService.js';


export default {
  name: "Home",
  components: {
  },
  data() {
    return {
    
    }
  },
  computed: {
    state() {
      return this.$store.state.state;
    },
    showbtn() {
      return this.$store.state.showbtn;
    },
    token() {
      return this.$store.state.token;
    }
  },
  methods: {
    claimGift() {
      
      // this.showbtn = false;
      // this.state = 'loading';

      this.$store.commit("updateData", {
          //Add the token to the data model
          token: this.token,
          // Activate the page
          showbtn: false
        });
      this.$store.commit("updateState", { state: 'loading' });


      //Get Incentive URL
        EventService.claimGift(this.token)
          .then( response => {
            
            var giftResponse = response.data;
            console.log(giftResponse)

            if (giftResponse) {
              // alert("Here's the URL: "+ giftResponse );
              setTimeout( () => { 
                window.location.href = giftResponse;
              }, 1500);

            }

          })
          .catch( error => {
            console.log(error);
          });

    }


  }
};
</script>

<style scoped>

  .faq {
    width: 100%;
    height: 54px;
    text-align: center;
  }
  .faq p {
    padding: 0 1rem;
  }


  .wrapper {
      background-color: #ffffff;

      width: 100%;
      height: 580px;
      max-width: 800px;
      
      position: relative;

      background: #ffffff url('/imgs/bg.png') 80% 80%;
      background-size: cover;

      margin-top: 2rem;
      margin-bottom: 0.5rem;
  }



  .card img,
  .headline img {
      width: 100%;
  }
  .card {
      max-width: 200px;
      margin: -1.5rem auto 0 auto;
  }
  .headline {
      max-width: 500px;
      margin: 0 auto 0 auto;
  }
  .headline h1 {
      padding: 1rem 0 0 0;
      margin: 0;
  }

  .headline p {
      max-width: 300px;
      margin: 0 auto 0 auto;
      text-align: center;
  }

  .btn {
    /* width: 100%; */
      background-color: #53565a;
      padding: 1rem 1.75rem;
      margin: 1rem 1.75rem;
      display: inline-block;
      border-radius: 0.3rem;

      color: #FFFFFF;
      text-decoration: none;
  
      transition: all 500ms;
      
      box-shadow: 0 0px 0px rgba(0, 0, 0, 0);

      border: none;
      font-size: 1rem;
      cursor: pointer;
      
  }
  .btn:hover {
    font-weight: normal;
    background-color: #34373a;
    transform: scale(1.1);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.7);
  }



  .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #53565a;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #53565a transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }



  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s ease
  }

  .fade-enter-from, .fade-leave-to {
    opacity: 0
  }


</style>