import { createStore } from "vuex";

export default createStore({
  state: {
    state: 'loading',
    showbtn: false,
    token: '',
  },
  mutations: {
    updateData: function(e, t) {
      e.token = t.token;
      e.showbtn = t.showbtn;
    },
    updateState: function(e, t) {
      e.state = t.state;
    },
  },
  actions: {},
  modules: {},
});
